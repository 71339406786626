import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Company from "../components/seo-companys"
import rightArrow from "../assets/img/right-arrow.png";

import social from "../assets/img/social-media-management.png"
import lion from "../assets/img/lion.png"
import sokrates from "../assets/img/sokrates.png"
import able from "../assets/img/able.png"
import eye from "../assets/img/eye.png"
import balance from "../assets/img/balance-time-money.png"
import achievement from "../assets/img/achievement-female.png"
import videoSearch from "../assets/img/video-search.png"
import cv from "../assets/img/cv-curriculum.png"
import maleMan from "../assets/img/male-man.png"
import business from "../assets/img/business-idea.png"
import work from "../assets/img/work-preformance.png"
import Teams from "../components/teams/social-team"
import SSS from "../components/sss/social-sss"

import tipsContent1 from "../assets/img/4-tips-conten1.png"
import tipsContent2 from "../assets/img/4-tips-conten2.png"
import tipsContent3 from "../assets/img/4-tips-conten3.png"
import tipsContent4 from "../assets/img/4-tips-conten4.png"
import circle from "../assets/img/circle.png"
import checkModal from "../assets/img/check-modal.png";


class SocialMedia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
            }
            else {
                document.querySelector('.status').innerHTML = "Bir hata oluştu.";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="Sosyal Medya Yönetimi" description="Her gun değişen sosyal medya trendlerini izliyor, kitleleri etkileyecek fark yaratan, alışılmışın dışında içerikler tasarlıyoruz." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1>Sosyal Medya</h1>
                            <span className="red-head">HİZMETLER</span>
                            <p>Her gün değişen sosyal medya trendlerini izliyor, kitleleri etkileyecek fark yaratan, alışılmışın dışında içerikler tasarlıyoruz. </p>
                            <input type="text" className="pink-input" placeholder="E-posta adresinizi girin..." value={this.state.value} onChange={this.handleChange} />
                            <button className="red-button" id="social-button" onClick={(e) => this.showModal()}>Size özel teklif alın <img alt="alt text" src={rightArrow}></img></button>
                        </div>
                        <div className="col-md-6 social" style={{ marginTop: 33.5}}><img alt="Sosyal Medya Yönetimi" src={social} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  placeholder="Websitenizi girin." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
                                    <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form>
                                    <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus id="site" required  />
                                    <input type="text" placeholder="Adınızı girin." />
                                    <input type="email" placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container brands_content py60">
                    <h2>
                        Öne çıkan markalarımızdan bazıları.
                <svg className="comment-icon" id="Group_817" data-name="Group 817" xmlns="http://www.w3.org/2000/svg" width="50.007" height="55.09" viewBox="0 0 87.007 85.09">
                            <g id="Group_813" data-name="Group 813" transform="translate(0 0)">
                                <path id="Path_23741" data-name="Path 23741" d="M70.6,0H16.409A16.409,16.409,0,0,0,0,16.409V46.938A16.409,16.409,0,0,0,16.409,63.347h5.263a4.458,4.458,0,0,1,3.316,1.478l17.223,19.16a3.336,3.336,0,0,0,5.034-.083L63.2,64.935a4.459,4.459,0,0,1,3.412-1.588H70.6A16.409,16.409,0,0,0,87.007,46.938V16.409A16.41,16.41,0,0,0,70.6,0Z" transform="translate(0 0)" fill="#ed4a57" />
                            </g>
                            <g id="Group_816" data-name="Group 816" transform="translate(17.681 19.866)">
                                <g id="Group_814" data-name="Group 814">
                                    <path id="Path_23742" data-name="Path 23742" d="M311.748,326.479a7.388,7.388,0,0,0-13.507-3.646.183.183,0,0,1-.306,0A7.387,7.387,0,1,0,287.4,332.884l9.87,9.095a.991.991,0,0,0,1.333.009l10-8.984A7.379,7.379,0,0,0,311.748,326.479Z" transform="translate(-284.414 -319.562)" fill="#fff" />
                                </g>
                                <g id="Group_815" data-name="Group 815" transform="translate(39.121 1.067)">
                                    <path id="Path_23743" data-name="Path 23743" d="M918.009,339.391h-.065l-3.68,1.987-.553-2.182,4.625-2.475h2.443v21.17h-2.768Z" transform="translate(-913.71 -336.72)" fill="#fff" />
                                </g>
                            </g>
                        </svg>
                    </h2>
                    <div className="companys__social">
                        <Company />
                    </div>
                </div>

                <div className="acquire-bottom social-media-contents">
                    <div className="acquire-bottom__right">
                        <div className="acquire-bottom__left-content">
                            <h2>Eğlenceli, yaratıcı<br />
                                ve fark yaratan <br />
                                içerik üretimi.</h2>
                            <p>
                                Sosyal medya dinamiklerine
uygun içerik tasarımı yapıyoruz.</p>
                        </div>
                        <div className="acquire-bottom__right-img"><img alt="alt text" src={lion} /></div>
                    </div>

                    <div className="tips-content container">
                        <p className="center-img">İÇERİK</p>
                        <img alt="alt text" className="circ" src={circle} />
                        <div className="row">
                            <div className="col-md-6 tcont">
                                <img alt="alt text" src={tipsContent1} />
                                <p className="firstImgP">
                                    Konsepti <br />oluşturuyoruz.
                        </p>
                            </div>
                            <div className="col-md-6 tcont">
                                <img alt="alt text" src={tipsContent2} />
                                <p className="secImgP">
                                    Sonra içeriği <br />yönetiyoruz.
                    </p>
                            </div>
                            <div className="col-md-6 tcont gizle2">
                                <img alt="alt text" src={tipsContent3} />
                            </div>
                            <div className="col-md-6 tcont gizle2">
                                <img alt="alt text" src={tipsContent4} />
                            </div>
                        </div>
                    </div>

                    <div className="acquire-bottom__left">
                        <div className="acquire-bottom__left-img"><img alt="alt text" src={sokrates} /></div>
                        <div className="acquire-bottom__left-content">
                            <h2>Topluluk <br />yönetimi.</h2>
                            <p>Hedef kitlenizi tanıyoruz. Dolayısıyla onlarla en doğru mecralarda doğru bir şekilde temas kuruyoruz. Kriz anında ise doğru aksiyon alarak sadakat yaratıyoruz. </p>
                        </div>
                    </div>

                    <div className="acquire-bottom__right" >
                        <div className="acquire-bottom__left-content" id="icerik-pazarlama">
                            <h2>İtibar <br />yönetimi.</h2>
                            <p>Markanızın çizgisine ve imajınıza uygun sosyal medya profili oluşturuyor, markanızı dijital mecralarda doğru konumlandırıyoruz. </p>
                        </div>
                        <div className="acquire-bottom__right-img"><img alt="alt text" src={able} /></div>
                    </div>

                    <div className="acquire-bottom__left">
                        <div className="acquire-bottom__left-img"><img alt="alt text" src={eye} /></div>
                        <div className="acquire-bottom__left-content">
                            <h2> Sosyal medya<br />kampanyaları.</h2>
                            <p>Hedeflediğiniz kitleyi etkisi altına alacak ve dijital dünyada viral olarak büyüyerek sizi bir adım öteye taşıyacak sosyal medya kampanyaları kurguluyoruz.</p>
                           

                        </div>
                    </div>

                </div>


                <div className="container manage-google" style={{ padding: '40px 60px' }}>
                    <h2>Sosyal medya yönetimi için <br />neden bizi seçmelisiniz?</h2>
                    <p className="manage-google_p">Veri odaklı pazarlama stratejileri, dikkatleri toplayan yaratıcı <br />kampanyalar, anında kriz yönetimi ve sonuç analizi sunuyoruz.</p>
                    <div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={balance} />
                            <div>
                                <h3>İleri Seviyede Uzmanlarla Çalışma</h3>
                                <p>Sosyal medyanızı stajyer veya az deneyimli kişiler değil, alanında uzman bir ekip yönetir.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={achievement} />
                            <div>
                                <h3>Değer Oluşturma</h3>
                                <p>Yatırım geri dönüşünüzü artırmak için yüksek etkili çalışmaya odaklanır ve bu hedefe yönelik belirlediğimiz KPI’ları sürekli olarak ölçümleriz.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={videoSearch} />
                            <div>
                                <h3>Etkili İletişim Yöntemleri </h3>
                                <p>Sosyal medya kampanyalarının performansını gözden geçirmek ve yeni fikirleri tartışmak için sizinle düzenli olarak  görüşmeler gerçekleştiririz.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={cv} />
                            <div>
                                <h3>Size Özel Rapor</h3>
                                <p>Tüm performans göstergelerinizin sonuçlarını ve markanız için en önemli istatistikleri, düzenli olarak rapor halinde sunarız.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={maleMan} />
                            <div>
                                <h3>Doğru Marka Konumlandırma</h3>
                                <p>Doğru kitleyle doğru mecralarda etkileşime geçerek markanızı imajınıza uygun şekilde konumlandırır ve olumlu bir algı yaratmak için viral gücü olan çalışmalara imza atarız.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={business} />
                            <div>
                                <h3>Uygun Fiyatlandırmalar</h3>
                                <p>Ücret, marka hedefleriniz ve çalışma kapsamına göre belirlenir. Bu doğrultuda aylık bir ön ödeme alınır. Bunun dışında istisnai ücretler talep edilmez.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="onboarding container py60" >
                    <div className="col-md-4">
                        <img alt="alt text" src={work} />
                        <h2>İşleyiş <br />sürecimiz.</h2>
                        <p>Markalarımız için sosyal medya yönetimi sürecimizi nasıl <br />sürdürdüğümüze göz atın.</p>
                    </div>
                    <div className="col-md-8">
                        <div className="hiring-tabs">
                            <input type="checkbox" name="onboarding" id="tab6" />
                            <input type="checkbox" name="onboarding" id="tab7" />
                            <input type="checkbox" name="onboarding" id="tab8" />
                            <input type="checkbox" name="onboarding" id="tab9" />
                            <input type="checkbox" name="onboarding" id="tab10" />
                            <input type="checkbox" name="onboarding" id="tab11" />

                            <div className="tab" id="tab6sec">
                                <label htmlFor="tab6"> </label>
                                <h3> <span>1</span> Keşif görüşmesi yapılır.</h3>
                                <p>- Hedefleriniz, rekabet avantajlarınız, hedef kitleniz, performansınızı etkileyen faktörler gibi markanızı ve hizmet sunduğunuz sektörü olabildiğince iyi tanımak için öncelikle bir görüşme gerçekleştiriyoruz. </p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab7sec">
                                <label htmlFor="tab7"> </label>
                                <h3> <span>2</span> Hesaplar denetlenerek fırsatlar ortaya çıkarılır.</h3>
                                <p>- Stratejilerimizi oluştururken yararlanacağımızı veriler doğrultusunda mevcut performansınızı analiz ediyoruz.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab8sec">
                                <label htmlFor="tab8"> </label>
                                <h3> <span>3</span> Sonraki adımlara geçmek üzere <br />bulgular konuşulur.</h3>
                                <p>- Büyüme fırsatlarını, ayrıntılı iş planını ve yatırım getirisi hesaplamalarını sunmak için yüz yüze veya görüntülü bir konuşma gerçekleştiriyoruz.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab9sec">
                                <label htmlFor="tab9"> </label>
                                <h3> <span>4</span> Başlangıç stratejisi oluşturulur ve aksiyona geçilir.</h3>
                                <p>- Audit sonucunda elde edilen bulgulara dayanan ilk 3 aylık öncelikli plana uygun ve dijital dünyada olumlu etkiye sahip olacak eylemlere odaklanıyoruz.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab10sec">
                                <label htmlFor="tab10"> </label>
                                <h3> <span>5</span> Optimizasyonlar ve aylık detaylı raporlamalar sürdürülür.</h3>
                                <p>- Performansı gözden geçirmek, gündemi takip etmek ve yeni fikirler üretmek üzere sizinle haftalık olarak görüşüyoruz. </p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab11sec">
                                <label htmlFor="tab11"> </label>
                                <h3> <span>6</span> Her üç ayda bir gelecek çeyreğe ilişkin <br /> stratejik planlama oturumları gerçekleştirilir. </h3>
                                <p>- Devam eden optimizasyonlar ve stratejik plan masaya yatırılarak sonraki döneme ilişkin ayrıntılı bir analiz değerlendirmesi yapılır, bütçenizin en yüksek değer sunacak şekilde yönetilmesi sağlanır.</p>
                                <div className="plus"></div>
                            </div>
                        </div>
                    </div>
                </div>
                { /* <div className="team">
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Size yardımcı olabilecek <br />sosyal medya uzmanlarımız. </h2>
                    <Teams />
                </div>*/}
                <div className="hiring" style={{ marginTop: 10 }}>
                    <h2>Sıkça Sorulan Sorular</h2>
                    <SSS />
                </div>
            </Layout>
        )
    }
}
export default SocialMedia
